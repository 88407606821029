@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto+Slab&family=Roboto:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    .black-bg {
        background-color: #152024;
    }

    .headline {
        font-family: "Roboto Slab", sans-serif;
    }

    .content-text {
        font-family: "Roboto", sans-serif;
    }

}